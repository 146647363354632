::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--color-secondary-2);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-blue-1);
}

// FIREFOX
:root {
  scrollbar-color: var(--color-blue-1) var(--color-secondary-2) !important;
  scrollbar-width: thin !important;
}
