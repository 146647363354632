.bold {
  font-weight: bold !important;
}

.fw-700 {
  font-weight: 700;
}

.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

.fw-400 {
  font-weight: 400;
}

.fw-300 {
  font-weight: 300;
}

.fw-200 {
  font-weight: 200;
}

.text-center {
  text-align: center !important;
}

.mb-10 {
  margin-bottom: 10px;
}
.mb-12 {
  margin-bottom: 12px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.text-left {
  text-align: left;
}

.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.mtb-6 {
  margin: 6px 0;
}

.mtb-12 {
  margin: 12px 0;
}

.mt-12-b-24 {
  margin-top: 12px;
  margin-bottom: 24px;
}

.danger {
  color: #e74c3c;
}
.success {
  color: #2ecc71;
}
.link-color {
  color: rgb(15, 139, 225);
}
.orange {
  color: #f39c12;
}

.purple {
  color: #9b59b6;
}

.silver {
  color: #bdc3c7;
}

.color-primary {
  color: #0071ce;
}

.color-text {
  color: #646668;
}

.color-black {
  color: var(--color-black);
}

.flex {
  display: flex;
}
.aic {
  align-items: center;
}

.link {
  color: var(--color-blue-1);
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
  // word-break: break-all;
}

.text-center {
  text-align: center;
}

.wide {
  width: 100%;
}
input:focus {
  background-color: #fff !important;
}
select:focus {
  background-color: #fff !important;
}

.underline {
  text-decoration: underline;
}

// .MuiAccordionDetails-root{background:#F7F7F7}

// .bdhjoO ul li:first-child .link-button,
// .bdhjoO ul li:first-child .nav-button{ margin-bottom: 8px !important;}
// .bdhjoO{ background: #2B2C2C !important; text-transform: uppercase;}
// .hxIlnt .app-wrapper{ background: #E5E5E5 !important;}
// .hxIlnt main{ background: #fff;
// margin-top: 58px !important; margin-bottom: 58px !important;
// border: 1px solid #EBEBEB;

// border-radius: 8px; padding: 48px !important; }
// .dNTbWG{ background: #F7F7F7 !important;}
// .bdhjoO .section-contacts li{ text-align: left !important;}
// input:focus {
//   background-color: #FFF !important;
// }
// select:focus {
//   background-color: #FFF !important;
// }
// .sc-xiLah{background-color:#2B2C2C !important}
// .kWHVIF a, .kWHVIF button{ background: #F7F7F7; }
// .iHpDqk{ background: #fff;}
// .iHpDqk .header{ text-align: left !important;}
// .iHpDqk .header .offer-top-label{ background: #E6F4FF !important; }
// .iHpDqk .offer-details-name,
// .iHpDqk .offer-details-value,
// .iHpDqk .offer-details span{ color: #2B2C2C !important;}
// .iHpDqk .offer-details-name{ font-weight: normal !important;}

// .jXfcnl{background: #fff !important; }
// .cUpwzv{background: #F7F7F7 !important;}
// .eLwvRy{ background: #F1F1F7 !important;}
// .bDiiyy{background: #fff !important;}
// .jFwdlS{background: #fff !important;}
// .icojMb .app-wrapper{background: #F1F1F7 !important;}
// .ibWHCu{background: #F1F1F7 !important;}
// .bdhjoO .section-header{ height: inherit !important;}
